<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>EasyKash</h1>
        <h1>Offers HASSLE-FREE </h1>
        <h1>way to apply for loans</h1>
        <h4>Without having to visit a physical branch or go </h4>
        <h4>through a lengthy verification process!</h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div id="selector1" class="inner inner2">
      <img class="left_bg" src="@/assets/images/inner2_left.png">
      <h3>ABOUT US</h3>
      <p>Hollandale Nigeria Limited is an international non-bank financial institution founded in 2020.
        In 2022 company expanded to Nigeria&Ghana. The company operates in 2 countries and
        focuses on lending Primarily to people with little or no credit history. As of 2023 the company
        has served already over 7 million active customers.
      </p>
      <div class="inner5_item">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <img :src="item.img" alt="">
          <div class="item_info">
            <h4>{{ item.title }}</h4>
            <p>{{ item.txt }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector2" class="inner inner3">
      <div class="title">
        <h3>FEATURES</h3>
        <p>Here are some of the key features of EasyKash that make it a great option for those looking for a loan</p>
      </div>
      <ul class="list">
        <li class="left_item">
          <p class="text1">Quick and easy loan application</p>
          <p class="text2">Here are some of the key features of EasyKash that make<br>
            it a great option for those looking for a loan</p>
        </li>
        <li class="right_item">
          <p class="text1">Flexible repayment options</p>
          <p class="text2">We offer a range of repayment options, making the loan<br>
            repayment process more manageable and less stressful</p>
        </li>
        <li class="left_item last">
          <p class="text1">Range of loan products</p>
          <p class="text2">Our app offers a variety of loan products, catering to different<br>
            financial needs, so you can choose the one that’s right for you</p>
        </li>
        <li class="right_item last">
          <p class="text1">Competitive Interest Rates</p>
          <p class="text2">We offer competitive interest rates, which means you can<br>
            save on loan costs while still getting the financial<br>
            assistance you need..</p>
        </li>
        <li class="left_item last">
          <p class="text1">Transparent Loan Terms</p>
          <p class="text2">We provide clear and transparent terms and conditions for<br>
            all loans, so you know exactly what you’re agreeing to before<br>
            accepting the loan</p>
        </li>
        <li class="right_item last">
          <p class="text1">Customer Support</p>
          <p class="text2">Our dedicated customer support team is always ready to assist you,<br>
            answering any questions or concerns you have during the loan process</p>
        </li>
      </ul>
      <div class="phone">
        <img src="@/assets/images/loanImg/phone.png" alt="">
      </div>
    </div>
    <div id="selector3" class="inner inner4">
      <h3>HOW TO BORROW MONEY IN EasyKash?</h3>
      <img src="@/assets/images/inner2_bg.png" alt="">
      <div class="inner5_item">
        <div v-for="(item,index) in leftItemList2" :key="index" class="content">
          <div class="item_info">
            <!-- <h2>{{ item.title }}</h2> -->
            <p>{{ item.txt }}</p>
            <p>{{ item.txt2 ? item.txt2 : '&nbsp;' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [
        { title: 1, txt: 'Download the app Search “EasyCash” from the Google Play Store and download it.', img: require('@/assets/images/content01.png') },
        { title: 2, txt: 'Register Sign up with your phone number, finish some infomation in 2 minutes', img: require('@/assets/images/content02.png') },
        { title: 3, txt: 'Apply for a loan Click the “Submit” button to apply for a loan.After that, the money will be received to your bank account.', img: require('@/assets/images/content03.png') }
      ],
      leftItemList: [
        { txt: 'Altruism', img: require('@/assets/images/Product01.png') },
        { txt: 'Tenacity', img: require('@/assets/images/Product02.png') },
        { txt: 'Innovate', txt2: 'mortgage', img: require('@/assets/images/Product03.png') },
        { txt: 'Integrity', img: require('@/assets/images/Product04.png') },
        { txt: 'Respect', img: require('@/assets/images/Product05.png') }
      ],
      leftItemList2: [
        { title: 'Download  app', txt: 'Sign Up ' },
        { title: 'Register an account', txt: 'Fill In Info' },
        { title: 'Fill out online forms', txt: 'Waiting For Review' },
        { title: 'Submit the application', txt: 'Get Loan' },
        { title: 'Submit the application', txt: 'Select Amount ', txt2: 'And Duration' },
        { title: 'Submit the application', txt: 'Repay' }
      ],
      inner6ItemLeftList: [
        { title: 'Danna Roberto', txt: 'Famous loan application app,the most', txt2: 'convenient way to get a loan for those who ', txt3: 'need o borrow money', img: require('@/assets/images/ng_1.png') },
        { title: 'Miller Abigail', txt: 'Very good app to help you use whenyou are ', txt2: 'in trouble, the app is easy tounderstand, ', txt3: 'everyone can submit', img: require('@/assets/images/ng_2.png') },
        { title: 'Davis Aron', txt: 'Very little information required, verysimple, ', txt2: 'hope to receive money soon,easy to apply, ', txt3: '5 stars', img: require('@/assets/images/ng_3.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=easykash.creditapp.loan'
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1100px) / 2);
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% 100%;
      height: 650px;
      display: flex;
      justify-content: space-between;
      .left_item{
        box-sizing: border-box;
        padding: 60px 0px 0;
        border-radius: 10px;
        // width: 500px;
        h1{
          font-size: 45px;
          color: white;
          font-weight: 700;
          // margin-bottom: 10px;
          line-height: 60px;
          span{
            color: #285DFD;
          }
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: #FFFFFF90;
          font-weight: normal;
          // margin: 0px 0;
        }
        & h4:nth-of-type(1){
          margin-top: 20px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          cursor: pointer;
          margin-top: 30px;
          color: white;
          border-radius: 5px;
          img{
            width: 160px;
            height: 50px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        img{
          height: 376px;
          margin-right: 30px;
        }
      }
    }
    .inner2{
      position: relative;
      .left_bg{
        position: absolute;
        top: 45px;
        left: 0;
        width: auto;
        height: 45%;
      }
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #4776FD;
        //   margin-top: 20px;
        // }
      }
      p{
        padding: 10px 200px 60px;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
        border-bottom: 1px solid #D0D0D0;
      }
      .inner5_item{
        display: flex;
        justify-content: space-between;
        margin: auto;
        .content{
          padding: 30px 10px 20px;
          color: #333333;
          width: 22%;
          border-radius: 15px;
          display: flex;
          // font-weight: 600;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            width: 76px;
            height: 76px;
          }
          p{
            font-size: 13px;
            font-weight: bold;
            padding: 0;
            border-bottom: 0;
          }
        }
      }
    }
    .inner3{
      position: relative;
      background: url("~@/assets/images/loanImg/bn.png") no-repeat center bottom;
      background-size: 1920px auto;
      padding:50px calc((100% - 1300px) / 2) 0;
      .phone{
        position: absolute;
        top: 210px;
        left: 50%;
        transform: translate(-50%);
        width: 350px;
        img{
          width: 100%;
        }
      }
      .title{
        text-align: center;
        margin-bottom: 140px;
        p{
          color: #666666;
          font-size: 16px;
          margin: 0;
        }
        h3{
          font-size: 24px;
          color: black;
          text-align: center;
          margin-bottom: 30px;
          position: relative;
          // &::after{
          //   display: block;
          //   content:'';
          //   width: 80px;
          //   height: 5px;
          //   background: #4776FD;
          //   margin-top: 20px;
          // }
        }
      }
      .list{
        width: 1400px;
        margin: 0 auto;
        overflow: hidden;
        // padding: 50px 0px;
        li{
          width: 650px;
          float: left;
          height: 225px;
          margin-bottom: 46px;
          img{
            width: 30px;
            height: 30px;
            margin-bottom: 20px;
          }
          .text1{
            font-size: 22px;
            color: #333333;
            font-weight: 700;
            line-height: 45px;
          }
          .text2{
            font-size: 14px;
            color: #666666;
            line-height: 19px;
          }
          &.left_item{
            text-align: left;
          }
          &.right_item{
            text-align: right;
          }
          &.last{
            .text1,.text2{
              color: #ffffff;
            }
          }
        }
      }
    }
    .inner4{
      padding-bottom: 80px;
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 50px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #4776FD;
        //   margin-top: 20px;
        // }
      }
      img{
        width: 100%;
        padding: 0 0px 0 10px;
      }
      .inner5_item{
        display: flex;
        justify-content: space-between;
        margin: auto;
        // padding: 0 0 0 30px;
        .content{
          color: #333333;
          flex: 1;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p{
            font-size: 13px;
            line-height: 24px;
          }
          & p:nth-of-type(1){
            padding: 10px 0 0 0;
          }
        }
        & .content:nth-of-type(1){
          flex: 0.9;
        }
        & .content:nth-of-type(2){
          flex: 0.9;
        }
        & .content:nth-of-type(3){
          flex: 0.9;
        }
        & .content:nth-of-type(4){
          flex: 0.92;
        }
        & .content:nth-of-type(5){
          flex: 0.88;
        }
        & .content:nth-of-type(6){
          flex: 0.60;
        }
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;;
      height: 300px;
      position: relative;
      background-size: 100% auto;
      .left_item{
        margin: 0px 10px 10px;
        // height: 500px;
        padding: 30px 0px;
        flex: 1;
        h1{
          font-size: 14px;
          line-height: 25px;
          // line-height: 50px;
        }
        h4{
          color: black;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 50px 20px;
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
    }
    .inner3{
      padding:  0;
      background-size: 100% auto;
      h3{
        width: 100%;
        margin-bottom: 30px;
      }
      .title{
        margin-bottom: 20px;
      }
      .text1{
        text-align: center;
        line-height: 30px !important;
        margin-bottom: 10px;
      }
      .text2{
        font-size: 12px !important;
      }
      p{
        padding: 0 20px;
        line-height: 20px;
        text-align: left !important;
        color: black !important;
      }
      .phone{
        width: 200px;
        position: relative;
        top: 0;
        margin: 30px 0;
      }
      .list{
        width: 100%;
        display: flex;
        flex-direction: column;
        li{
          width: 100%;
          height: 100px;
          margin-bottom: 15px;
        }
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner4{
      padding: 50px calc((100% - 1100px) / 2) 0;
      h3{
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      img{
        padding: 0;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .content{
          width: 200px;
          flex: 1 !important;
          p{
            width: 100%;
            font-size: 12px !important;
          }
          // margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
